<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Lobbies" subtitle="Use lobbies as a quick way to find scheduled games and chat with fellow gamers that have similar interests.">

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-grid="true">

		<app-card to-name="Convention.Schedule.Lobby" :to-id="item.id" v-for="item in collection" tooltip="Click to view lobby" :key="item.id" :image="item.image" :title="item.name">

			<app-card-action tooltip="View lobby" icon="more" to-name="Convention.Schedule.Lobby" :to-id="item.id" />
			<app-card-action tooltip="Click to Subscribe" :loading="is.subscribing === item.id" v-on:click="onSubscribeClick(item.id)" v-if="!item.user.subscribed" icon="subscribe" />
			<app-card-action tooltip="Click to Unsubscribe" :loading="is.subscribing === item.id" v-on:click="onUnsubscribeClick(item.id)" :active="true" v-if="item.user.subscribed" icon="subscribe" />

		</app-card>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			is: {
				subscribing: false
			},
			pagination: false,
			endpoint: 'convention/schedule/lobbies',
			live: 'convention/schedule/lobby'
		}

	},

	methods: {

		onSubscribeClick: function(id) {

			this.is.subscribing = id

			this.$api.post(['convention/schedule/lobby', id, 'subscribe']).then(function() {

				this.is.subscribing = false

			}.bind(this))

		},

		onUnsubscribeClick: function(id) {

			this.is.subscribing = id

			this.$api.delete(['convention/schedule/lobby', id, 'subscribe']).then(function() {

				this.is.subscribing = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>